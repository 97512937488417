import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a5bc5dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card card-flush pt-3 mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "fw-bolder" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = { class: "card-body pt-3" }
const _hoisted_7 = { class: "mb-10" }
const _hoisted_8 = { class: "d-flex flex-wrap py-5" }
const _hoisted_9 = { class: "flex-equal me-5" }
const _hoisted_10 = {
  class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0",
  style: {"table-layout":"fixed","width":"100%"}
}
const _hoisted_11 = { class: "text-gray-400 min-w-175px w-200px" }
const _hoisted_12 = { class: "svg-icon svg-icon-1 svg-icon-gray" }
const _hoisted_13 = { class: "text-gray-800 min-w-200px text-hover-gray" }
const _hoisted_14 = { class: "text-gray-400 min-w-175px w-200px" }
const _hoisted_15 = { class: "svg-icon svg-icon-1 svg-icon-warning" }
const _hoisted_16 = { class: "text-gray-800 min-w-200px text-hover-primary" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "text-gray-400" }
const _hoisted_19 = { class: "svg-icon svg-icon-1 svg-icon-danger" }
const _hoisted_20 = { class: "text-gray-800" }
const _hoisted_21 = { class: "text-gray-400" }
const _hoisted_22 = { class: "svg-icon svg-icon-1 svg-icon-success" }
const _hoisted_23 = { class: "text-gray-800" }
const _hoisted_24 = { class: "text-gray-400" }
const _hoisted_25 = { class: "svg-icon svg-icon-1 svg-icon-primary" }
const _hoisted_26 = { class: "badge-light-primary badge fs-7 px-5 fw-bolder text-gray-400" }
const _hoisted_27 = { key: 1 }
const _hoisted_28 = { class: "text-gray-400" }
const _hoisted_29 = { class: "svg-icon svg-icon-1 svg-icon-danger" }
const _hoisted_30 = { class: "badge-light-danger badge fs-7 px-5 fw-bolder text-gray-400" }
const _hoisted_31 = { class: "text-gray-400" }
const _hoisted_32 = { class: "svg-icon svg-icon-1 svg-icon-success" }
const _hoisted_33 = { class: "text-gray-800" }
const _hoisted_34 = { class: "text-gray-400" }
const _hoisted_35 = { class: "svg-icon svg-icon-1 svg-icon-warning" }
const _hoisted_36 = { class: "text-gray-800" }
const _hoisted_37 = {
  key: 0,
  class: "badge-light-primary bg-light-primary badge fs-7 p-2 fw-bolder"
}
const _hoisted_38 = { class: "text-gray-400" }
const _hoisted_39 = { class: "svg-icon svg-icon-1 svg-icon-gray" }
const _hoisted_40 = {
  key: 0,
  class: "badge-light-danger bg-light-danger badge fs-7 p-2"
}
const _hoisted_41 = {
  key: 1,
  class: "badge-light-success bg-light-success badge fs-7 p-2"
}
const _hoisted_42 = {
  key: 2,
  class: "badge-light-info bg-light-info badge fs-7 p-2"
}
const _hoisted_43 = { key: 2 }
const _hoisted_44 = { class: "text-gray-400" }
const _hoisted_45 = { class: "svg-icon svg-icon-1 svg-icon-gray" }
const _hoisted_46 = ["innerHTML"]
const _hoisted_47 = { class: "d-flex flex-center mb-5 fv-row" }
const _hoisted_48 = { style: {"margin-left":"50px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Submissions = _resolveComponent("Submissions")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.t('course.infoExercise')), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.exercise.status != 'validated')
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: `/classe/${_ctx.classID}/editQuiz/${_ctx.homeworkID}`,
                class: "btn btn-light-primary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('course.edit')), 1)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.confirmDelete && _ctx.confirmDelete(...args))),
            class: "btn btn-light-danger mx-5"
          }, _toDisplayString(_ctx.t('course.delete')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("table", _hoisted_10, [
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_11, [
                    _createElementVNode("span", _hoisted_12, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen007.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('notes.trimester')) + " : ", 1)
                  ]),
                  _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.exercise.trimester == '0'
                      ? _ctx.t('course.allTrimesters')
                      : `${_ctx.t('notes.trimester')} ${_ctx.exercise.trimester}`), 1)
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_14, [
                    _createElementVNode("span", _hoisted_15, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen003.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('course.subject')) + " : ", 1)
                  ]),
                  _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.exercise.subject), 1)
                ]),
                (_ctx.exercise.module)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_17, [
                      _createElementVNode("td", _hoisted_18, [
                        _createElementVNode("span", _hoisted_19, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen017.svg" })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t('course.module')) + " : ", 1)
                      ]),
                      _createElementVNode("td", _hoisted_20, _toDisplayString(_ctx.exercise.module), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_21, [
                    _createElementVNode("span", _hoisted_22, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen005.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('course.title')) + " : ", 1)
                  ]),
                  _createElementVNode("td", _hoisted_23, _toDisplayString(_ctx.exercise.title), 1)
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_24, [
                    _createElementVNode("span", _hoisted_25, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen014.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('course.publicationDate')) + " : ", 1)
                  ]),
                  _createElementVNode("td", _hoisted_26, _toDisplayString(_ctx.moment(_ctx.exercise.createdAt).format('YYYY/MM/DD, H:mm')), 1)
                ]),
                (_ctx.exercise.deadline)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_27, [
                      _createElementVNode("td", _hoisted_28, [
                        _createElementVNode("span", _hoisted_29, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen012.svg" })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t('course.deadline')) + " : ", 1)
                      ]),
                      _createElementVNode("td", _hoisted_30, _toDisplayString(_ctx.moment(_ctx.exercise.deadline).format('YYYY/MM/DD, H:mm')), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_31, [
                    _createElementVNode("span", _hoisted_32, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen037.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('course.marked')) + " : ", 1)
                  ]),
                  _createElementVNode("td", _hoisted_33, _toDisplayString(_ctx.exercise.marked ? _ctx.t('course.yes') : _ctx.t('course.no')), 1)
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_34, [
                    _createElementVNode("span", _hoisted_35, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com006.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('course.specificFor')) + " : ", 1)
                  ]),
                  _createElementVNode("td", _hoisted_36, [
                    (_ctx.studentList.length == 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(_ctx.t('course.allStudent')), 1))
                      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.studentList, (student) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: student._id,
                            class: "badge-light bg-light-info badge fs-7 p-2 mx-1 mb-2 fw-bolder"
                          }, _toDisplayString(student.firstName) + " " + _toDisplayString(student.lastName), 1))
                        }), 128))
                  ])
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_38, [
                    _createElementVNode("span", _hoisted_39, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen037.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('course.review')) + " : ", 1)
                  ]),
                  _createElementVNode("td", null, [
                    (_ctx.exercise.status == 'rejected')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_40, _toDisplayString(_ctx.t('course.rejectedFilter')) + " - " + _toDisplayString(_ctx.moment(_ctx.exercise.reviewDate).format('YYYY/MM/DD, H:mm')), 1))
                      : (_ctx.exercise.status == 'validated')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_41, _toDisplayString(_ctx.t('course.validatedFilter')) + " -" + _toDisplayString(_ctx.moment(_ctx.exercise.reviewDate).format('YYYY/MM/DD, H:mm')), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_42, _toDisplayString(_ctx.t('course.notValidatedFilter')), 1))
                  ])
                ]),
                (_ctx.exercise.reviewNote)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_43, [
                      _createElementVNode("td", _hoisted_44, [
                        _createElementVNode("span", _hoisted_45, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen005.svg" })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t('course.reason')) + " : ", 1)
                      ]),
                      _createElementVNode("td", {
                        innerHTML: _ctx.exercise.reviewNote
                      }, null, 8, _hoisted_46)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_47, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.t('course.quiz')), 1)
          ]),
          _createElementVNode("div", _hoisted_48, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exercise.questions, (question, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "py-5",
                key: index
              }, [
                _createElementVNode("h2", null, _toDisplayString(_ctx.t('course.question')) + " " + _toDisplayString(index + 1) + " : " + _toDisplayString(question.text), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.answers, (answer, aindex) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "padding-left: 40px",
                    key: aindex
                  }, [
                    _createElementVNode("h6", null, [
                      _createTextVNode(_toDisplayString(_ctx.t('course.answer')) + " " + _toDisplayString(aindex + 1) + ": ", 1),
                      _createElementVNode("span", {
                        class: _normalizeClass(`badge-light-${
                    answer.isCorrect ? 'success' : 'danger'
                  } bg-light-${
                    answer.isCorrect ? 'success' : 'danger'
                  } badge fs-7 p-2 fw-bolder m-1`)
                      }, _toDisplayString(answer.text), 3)
                    ])
                  ]))
                }), 128))
              ]))
            }), 128))
          ])
        ])
      ])
    ]),
    _createVNode(_component_Submissions, {
      homework: _ctx.homeworkID,
      questions: _ctx.exercise.questions,
      marked: _ctx.exercise.marked
    }, null, 8, ["homework", "questions", "marked"])
  ], 64))
}